<template>
    <div
        data-test-id="norton-commander"
        :data-test-finished-loading="!loading"
        :data-test-number-of-cashboxes="cashboxes.length"
        :data-test-filter="JSON.stringify(transactionFilter)"

        class="h-full w-full"
    >
        <selected-item
            ref="selectedItem"
            @deselect="clearSelection"
        />

        <full-overlay v-model="loading" />

        <adaptive-layout>
            <template #left>
                <cashbox-card
                    title="Кассы и счета"
                    :nodes="ourCashboxTree.nodes"
                    :filter="ourCashboxFilter"
                    :selected-item="selectedItem"
                    :cashbox-categories="cashboxCategories"
                    :cashboxes="cashboxes"

                    :busy="fetching.includes('cashboxes')"

                    :drag-scroll-area-visibility="dragScrollAreaVisibility"
                    :stop-scroll="stopScroll"

                    :index="0"

                    @transaction:dialog:create="openTransactionDialogCreate"
                    @cashbox:menu:mass-process="$refs.cashboxMenuContext.show($event)"
                    @dialog:set-amount="openDialog('dialogSetAmount', $event)"

                    @drag:start="dragScrollAreaVisibility = true"
                    @drag:end="dragScrollAreaVisibility = false"

                    @scroll:start="stopScroll = false"
                    @scroll:stop="stopScroll = true"

                    @select-item="selectItem"
                />
            </template>

            <template #middle>
                <cashbox-card
                    title="Расходы"
                    :nodes="vendorCashboxTree.nodes"
                    :filter="vendorCashboxFilter"
                    :selected-item="selectedItem"
                    :cashbox-categories="cashboxCategories"
                    :cashboxes="cashboxes"

                    :busy="fetching.includes('cashboxes')"

                    :drag-scroll-area-visibility="dragScrollAreaVisibility"
                    :stop-scroll="stopScroll"

                    :index="1"

                    :add-to-total="costTotal"

                    @transaction:dialog:create="openTransactionDialogCreate"
                    @cashbox:menu:mass-process="$refs.cashboxMenuContext.show($event)"
                    @dialog:set-amount="openDialog('dialogSetAmount', $event)"

                    @drag:start="dragScrollAreaVisibility = true"
                    @drag:end="dragScrollAreaVisibility = false"

                    @scroll:start="stopScroll = false"
                    @scroll:stop="stopScroll = true"

                    @select-item="selectItem"
                >
                    <template #bottom>
                        <cost-list
                            :selected-item="selectedItem"
                            :update-costs-list="updateCosts"
                            :cost-categories="costCategories"
                            :cost-templates="costTemplates"
                            :cost-elements="costElements"

                            @transaction:dialog:create="openTransactionDialogCreate"
                            @dialog:set-amount="openDialog('dialogSetAmount', $event)"

                            @drag:start="dragScrollAreaVisibility = true"
                            @drag:end="dragScrollAreaVisibility = false"

                            @select-item="selectItem"
                            @new-cost-element="newCostElement"
                            @new-cost-template="newCostTemplate"

                            @update:costTotal="costTotal = $event"
                        />

                        <v-divider />

                        <cost-constructor />
                    </template>
                </cashbox-card>
            </template>

            <template #right>
                <transaction-list
                    :filter="transactionFilter || defaultFilter"
                    :filter-modifier="transactionFilterModifier"

                    :cashbox-categories="cashboxCategories"
                    :cashboxes="cashboxes"

                    :cost-categories="costCategories"
                    :cost-templates="costTemplates"
                    :cost-elements="costElements"

                    :users="users"
                    :statuses="statuses"
                    :date-helpers="dateHelpers"
                    :group-by-list="groupByList"

                    :is-filter-active="isFilterActive"

                    :do-update="updateTransactions"

                    :drag-scroll-area-visibility="dragScrollAreaVisibility"
                    :stop-scroll="stopScroll"

                    @transaction:dialog:process="openDialog('transactionDialogProcess', $event)"
                    @transaction:replace-cashbox="replaceCashboxInTransaction"

                    @filter:dialog:edit="openFilterDialogEdit"
                    @filter:dialog:saved-filters="openDialog('savedFiltersDialog')"
                    @filter:clear="clearTransactionFilter"
                    @filter:save="saveFilter"
                    @filter:modify="modifyFilter"

                    @report:menu:group-by="$refs.reportMenuGroupBy.show($event)"

                    @scroll:start="stopScroll = false"
                    @scroll:stop="stopScroll = true"
                />
            </template>
        </adaptive-layout>

        <cashbox-dialog-mass-process
            ref="cashboxDialogMassProcess"

            :cashbox-categories="cashboxCategories"
            :cashboxes="cashboxes"

            :cost-categories="costCategories"
            :costTemplates="costTemplates"

            :statuses="statuses"
        />

        <cashbox-menu-context
            ref="cashboxMenuContext"
            :user="currentUser"
            @cashbox:dialog:mass-process="openDialog('cashboxDialogMassProcess', $event)"
        />

        <transaction-dialog-create
            ref="transactionDialogCreate"
            @transaction:create="createTransaction"
        />

        <transaction-dialog-process
            ref="transactionDialogProcess"

            :cashbox-categories="cashboxCategories"
            :cashboxes="cashboxes"

            :cost-categories="costCategories"
            :cost-templates="costTemplates"
            :cost-elements="costElements"

            :statuses="statuses"
            :users="users"

            @transaction:dialog:create="openTransactionDialogCreate"
            @transaction:dialog:edit="openDialog('transactionDialogEdit', $event)"
            @transaction:process="processTransaction"
        />

        <transaction-dialog-edit
            ref="transactionDialogEdit"
            :cashbox-categories="cashboxCategories"
            :cashboxes-prop="cashboxes"
            @transaction:edit="editTransaction"
        />

        <filter-dialog-edit
            ref="filterDialogEdit"

            :cashbox-categories="cashboxCategories"
            :cashboxes="cashboxes"

            :cost-categories="costCategories"
            :cost-templates="costTemplates"

            :date-helpers="dateHelpers"
            :statuses="statuses"
            :users="users"

            @filter:apply="applyTransactionFilter"
            @filter:saved:edit="editSavedFilter"
        />

        <saved-filters-dialog
            ref="savedFiltersDialog"

            :saved-filters="savedFilters"

            :cashbox-categories="cashboxCategories"
            :cashboxes="cashboxes"

            :cost-categories="costCategories"
            :cost-templates="costTemplates"

            :date-helpers="dateHelpers"
            :statuses="statuses"
            :users="users"
            :group-by-list="groupByList"

            @filter:dialog:edit="openFilterDialogEdit"
            @filter:saved:apply="applySavedFilter"
            @filter:saved:delete="deleteSavedFilter"
        />

        <report-menu-group-by
            ref="reportMenuGroupBy"
            :filter="transactionFilter || defaultFilter"
            :groupByList="groupByList"
            @filter:apply="applyTransactionFilter"
        />

        <dialog-set-amount
            ref="dialogSetAmount"
            @cashbox:set-amount="setCashboxAmount"
            @cost:set-amount="setCostAmount"
        />
    </div>
</template>

<script>
import getCurrentUser from "@u/api/current-user";
import getCurrentPermissions from "@u/api/current-permissions";
import { getCashboxCategories, getCashboxes } from "@u/cashboxes/";
import { Tree } from "@u/treeview";

import statuses from "@u/norton-commander/statuses";
import dateHelpers from "@u/norton-commander/date-helpers";
import groupByList from "@u/norton-commander/group-by-list";
import cashboxGroups from "@u/cashboxes/cashbox-groups";

import FullOverlay from "@c/FullOverlay";

import AdaptiveLayout from "@c/norton-commander/layouts/AdaptiveLayout";

import SelectedItem from "@c/norton-commander/SelectedItem";

import CashboxCard from "@c/norton-commander/CashboxCard";
import CostList from '@c/norton-commander/costs/CostList';
import CostConstructor from '@c/norton-commander/costs/CostConstructor';
import TransactionList from "@c/norton-commander/transactions/TransactionList";

import CashboxDialogMassProcess from "@c/norton-commander/dialogs/cashbox/MassProcess";
import CashboxMenuContext from "@c/norton-commander/menus/cashbox/ContextMenu";

import TransactionDialogCreate from "@c/norton-commander/dialogs/transaction/TransactionCreate";
import TransactionDialogProcess from "@c/norton-commander/dialogs/transaction/TransactionProcess";
import TransactionDialogEdit from "@c/norton-commander/dialogs/transaction/TransactionEdit";

import FilterDialogEdit from "@c/norton-commander/dialogs/filter/FilterEdit";
import SavedFiltersDialog from "@c/norton-commander/dialogs/filter/SavedFilters";

import ReportMenuGroupBy from "@c/norton-commander/menus/report/GroupBy";

import DialogSetAmount from "@c/norton-commander/dialogs/SetAmount";

export default {
    name: "NortonCommander",

    components: {
        FullOverlay,

        AdaptiveLayout,

        SelectedItem,

        CashboxCard,
        CostList,
        CostConstructor,
        TransactionList,

        CashboxDialogMassProcess,
        CashboxMenuContext,

        TransactionDialogCreate,
        TransactionDialogProcess,
        TransactionDialogEdit,

        FilterDialogEdit,
        SavedFiltersDialog,

        ReportMenuGroupBy,

        DialogSetAmount,
    },

    props: {
        filter: {
            type: Object,
            required: true,
        }
    },

    data: () => ({
        statuses,
        dateHelpers,
        groupByList,
        cashboxGroups,

        loading: false,
        stopScroll: false,
        dragScrollAreaVisibility: false,

        cashboxCategories: [],
        cashboxes: [],
        costCategories: [],
        costTemplates: [],
        costElements: [],
        savedFilters: [],
        fields: [],
        fetching: [],
        ourCashboxFilter: [
            'account_api',
            'account',
            'refillbot',
            'vendor_cards',
            'employee_cards',
            'card',
            'cash',
            'safe',
            'manager',
            'driver',
        ],
        vendorCashboxFilter: [
            'vendor',
            'salary',
            'installments',
            'storage',
        ],
        ourCashboxes: [],
        vendorCashboxes: [],

        users: {},
        currentPermissions: {},
        currentUser: {},
        selectedItem: {},
        transactionFilterModifier: {
            type: undefined,
            value: undefined,
        },
        defaultFilter: {
            statuses: [
                'draft',
                'completed',
                'queue',
                'processing',
            ],
            cashboxesGroup: [],
            cashboxes: [],
            costCategories: [],
            costTemplates: [],
            userWhoCreated: [],
            userWhoProcessed: [],
            creationDate: null,
            processingDate: null,
            processingDateHelpers: null,
            siteTransactions: [],
            siteSplits: [],
            siteDeliveries: [],
            operationAmount: {
                from: '',
                to: '',
            },

            group: 'cashbox.any',
        },

        transactionFilter: undefined,
        savedFilterId: null,

        updateCosts: 0,
        updateFields: 0,
        updateCashboxes: 0,
        updateTransactions: 0,
        updateTimeout: 10000,
        costTotal: 0,
    }),

    async mounted() {
        this.transactionFilter = JSON.parse(JSON.stringify(this.defaultFilter));
        this.applyTransactionFilter(this.filter, false);

        this.loading = true;
        await Promise.all([
            this.getUsers(),
            this.getCurrentUser(),
            this.getCurrentPermissions(),

            this.getFields(),
            this.getFilters(),

            this.getCostElements(),
            this.getCostTemplates(),
            this.getCostCategories(),

            this.getCashboxes(),
        ]);
        this.loading = false;

        this.startUpdateTimer();
    },

    watch: {
        transactionFilter: {
            handler(){
                const typeChanged = this.transactionFilterModifier.type !== undefined;
                const valueChanged = this.transactionFilterModifier.value !== undefined;

                if (typeChanged || valueChanged) {
                    this.transactionFilterModifier = {
                        type: undefined,
                        value: undefined,
                    };
                }
            },
            deep: true,
        },

        updateCashboxes: {
            async handler() {
                await this.getCashboxes();
            },
        },
    },

    computed: {
        isFilterActive() {
            return JSON.stringify(this.transactionFilter) !== JSON.stringify(this.defaultFilter);
        },

        ourCashboxTree() {
            return new Tree({
                items: this.ourCashboxes,
                categories: this.cashboxCategories,
            });
        },

        vendorCashboxTree() {
            return new Tree({
                items: this.vendorCashboxes,
                categories: this.cashboxCategories,
            });
        },
    },

    methods: {
        async getFields() {
            try {
                this.fields = await this.$api("/cashbox/fields");
                this.updateFields = Math.random();
            } catch (e) {
                this.$error(e.message);
            }
        },

        async getUsers() {
            try {
                const users = await this.$api("/finance/transaction/filter/users");
                for (const user of users) {
                    const usersHelper = {};
                    user['name'] = user['login'];
                    usersHelper[user.id] = user;
                    this.users = Object.assign(
                        {},
                        this.users,
                        usersHelper,
                    );
                }
            } catch (e) {
                if (e.httpStatus != 403) {
                    this.$error(e.message);
                }
            }
        },

        async getCurrentPermissions() {
            try {
                this.currentPermissions = await getCurrentPermissions(this.$api);
            } catch (e) {
                this.$error(e.message);
            }
        },

        async getCurrentUser() {
            try {
                this.currentUser = await getCurrentUser(this.$api);
            } catch (e) {
                this.$error(e.message);
            }
        },

        async getFilters() {
            try {
                const result = await this.$api("/finance/transaction/filter");
                this.savedFilters = result;
                this.savedFilterId = Math.max(...result.map(e => e.id));
            } catch (e) {
                this.$error(e.message);
            }
        },

        async getCostCategories() {
            try {
                this.costCategories = await this.$api("/finance/costs/categories");
            } catch (e) {
                this.$error(e.message);
            }
        },

        async getCostTemplates() {
            try {
                this.costTemplates = await this.$api("/finance/costs/templates");
            } catch (e) {
                this.$error(e.message);
            }
        },

        async getCostElements() {
            try {
                const { list } = await this.$api("/finance/costs/elements");
                this.costElements = list;
            } catch (e) {
                this.$error(e.message);
            }
        },

        async getCashboxes() {
            this.fetching.push('cashboxes');
            try {
                if (!this.cashboxCategories.length) {
                    this.cashboxCategories = await getCashboxCategories(this.$api);
                }

                const apiCashboxes = await getCashboxes(this.$api, this.$apiResponse, this.fields);

                if (!this.cashboxes.length) {
                    this.cashboxes = apiCashboxes;
                }

                const apiOurCashboxes = apiCashboxes.filter(({ type }) => this.ourCashboxFilter.includes(type));
                if (JSON.stringify(this.ourCashboxes) !== JSON.stringify(apiOurCashboxes)) {
                    this.ourCashboxes = apiOurCashboxes;
                }

                const apiVendorCashboxes = apiCashboxes.filter(({ type }) => this.vendorCashboxFilter.includes(type));
                if (JSON.stringify(this.vendorCashboxes) !== JSON.stringify(apiVendorCashboxes)) {
                    this.vendorCashboxes = apiVendorCashboxes;
                }
            } finally {
                this.fetching = this.fetching.filter((i) => i !== 'cashboxes');
            }
        },

        async saveFilter(name) {
            try {
                const newFilter = {};

                Object.entries(this.defaultFilter).forEach(([key, defaultValue]) => {
                    const currentValue = this.transactionFilter[key];
                    if (JSON.stringify(currentValue) !== JSON.stringify(defaultValue)) {
                        newFilter[key] = currentValue;
                    }
                });

                this.savedFilterId++;

                const result = await this.$api(
                    "/finance/transaction/filter",
                    {
                        method: "POST",
                        body: {
                            id: this.savedFilterId,
                            name,
                            link: JSON.stringify(newFilter),
                        },
                    }
                );

                this.savedFilters.push(result);
            } catch (e) {
                this.$error(e.message);
            }
        },

        async editSavedFilter(filter, { id, name }) {
            // @todo объединить с предыдущим методом "saveFilter"
            try {
                const newFilter = {};

                Object.entries(this.defaultFilter).forEach(([key, defaultValue]) => {
                    const currentValue = filter[key];
                    if (JSON.stringify(currentValue) !== JSON.stringify(defaultValue)) {
                        newFilter[key] = currentValue;
                    }
                });

                const result = await this.$api(
                    "/finance/transaction/filter",
                    {
                        method: "PATCH",
                        body: {
                            id,
                            name,
                            link: JSON.stringify(newFilter),
                        },
                    }
                );

                this.savedFilters[this.savedFilters.findIndex(e => e.id === id)].link = result.link;
            } catch (e) {
                this.$error(e.message);
            }
        },

        async createTransaction(from, to, summ, comment) {
            let post = {
                operations: [],
                links: [],
            };

            if ((typeof comment !== 'undefined') && comment.length) {
                post.comment = comment;
            }

            const transaction = this.getTransactionMatrix(from, to);

            if (!transaction.isAllowed) {
                this.$error('Операция для ' + to.category + ' не поддерживается');
                return;
            }

            if (transaction.to !== null) {
                post.operations.push({
                    cashbox: to.id,
                    amount: summ * transaction.to,
                });
            }

            if (transaction.from !== null) {
                post.operations.push({
                    cashbox: from.id,
                    amount: summ * transaction.from,
                });
            }

            switch (transaction.addLink) {
                case 'to':
                    post.links.push({
                        id: to.id,
                        type: to.subcategory !== '' ? [to.category, to.subcategory].join('.') : to.category,
                    });
                    break;
                case 'from':
                    post.links.push({
                        id: from.id,
                        type: from.subcategory !== '' ? [from.category, from.subcategory].join('.') : from.category,
                    });
                    break;
            }

            if (!post.operations.length) {
                this.$error('Невозможно создать транзакцию без операций');
                return;
            }

            try {
                const createdTransaction = await this.$api(
                    "/finance/transaction",
                    {
                        method: "POST",
                        body: post,
                    }
                );
                if (typeof createdTransaction.id !== 'undefined') {
                    const updateNumber = Math.random();
                    this.updateTransactions = updateNumber;
                    switch (to.category) {
                        case 'costs': // @todo удалить при переделке шаблонов
                            this.updateCosts = updateNumber;
                            break;
                    }
                }
            } catch (e) {
                this.$error(e.message);
            }
        },

        update(obj) {
            if (typeof obj.id !== 'undefined') {
                // @todo убрать когда на ws будет
                setTimeout(() => {
                    this.updateTransactions = Math.random();
                    this.updateCashboxes = Math.random();
                    this.updateCosts = Math.random();
                }, 300);
            }
        },

        async processTransaction({ id, status, comment }) {
            try {
                const patchTransaction = await this.$api(
                    `/finance/transaction/${id}`,
                    {
                        method: "PATCH",
                        body: {
                            status,
                            comment,
                        },
                    }
                );
                this.update(patchTransaction);
            } catch (e) {
                this.$error(e.message);
            }
        },

        async replaceCashboxInTransaction({ id, operationId, cashboxId }) {
            let post = {
                operations: [
                    {
                        id: operationId,
                        cashbox: cashboxId,
                    }
                ],
            };

            try {
                const patchTransaction = await this.$api(
                    `/finance/transaction/${id}`,
                    {
                        method: "POST",
                        body: post,
                    }
                );
                if (typeof patchTransaction.id != 'undefined') {
                    // @todo убрать когда на ws будет
                    setTimeout(() => {
                        this.updateTransactions = Math.random();
                    }, 300);
                }
            } catch (e) {
                this.$error(e.message);
            }
        },

        applyTransactionFilter(filter, needRouteChange = true) {
            this.updateFilterFields(this.transactionFilter, filter);
            this.updateTransactions = Math.random();

            if (needRouteChange) {
                const newFilter = {};

                Object.entries(this.defaultFilter).forEach(([key, defaultValue]) => {
                    const currentValue = this.transactionFilter[key];
                    if (JSON.stringify(currentValue) !== JSON.stringify(defaultValue)) {
                        newFilter[key] = currentValue;
                    }
                });

                this.$router.push({
                    query: {
                        filter: JSON.stringify(newFilter),
                        t: Date.now(),
                    }
                });
            }
        },

        clearTransactionFilter() {
            this.applyTransactionFilter(this.defaultFilter, true);
        },

        applySavedFilter(savedFilter) {
            this.updateFilterFields(this.transactionFilter, this.defaultFilter);
            this.updateFilterFields(this.transactionFilter, savedFilter);
            this.$router.push({
                query: {
                    filter: JSON.stringify(savedFilter),
                    t: Date.now(),
                }
            });
            this.updateTransactions = Math.random();
        },

        async deleteSavedFilter(savedFilterId) {
            let post = {
                id: savedFilterId,
            };

            try {
                await this.$api(
                    "/finance/transaction/filter",
                    {
                        method: "DELETE",
                        body: post,
                    }
                );

                this.savedFilters.splice(
                    this.savedFilters.findIndex(item => item.id === savedFilterId),
                    1
                );
            } catch (e) {
                this.$error(e.message);
            }
        },

        updateFilterFields(filter, newFilter) {
            for (const field in filter) {
                if (typeof newFilter[field] == 'undefined') {
                    continue;
                }
                if (newFilter[field] === null) {
                    filter[field] = null;
                    continue;
                }
                if (Array.isArray(filter[field])) {
                    filter[field].splice(0, filter[field].length);
                    for (const item of newFilter[field]) {
                        filter[field].push(item);
                    }
                } else {
                    if (filter[field] === null) {
                        filter[field] = newFilter[field];
                    } else {
                        switch (typeof filter[field]) {
                            case 'object':
                                this.updateFilterFields(filter[field], newFilter[field]);
                                break;
                            default:
                                filter[field] = newFilter[field];
                        }
                    }
                }
            }
        },

        async setCashboxAmount({ id, amount }) {
            try {
                const post = {
                    values: [],
                };
                for (const field of this.fields) {
                    if (field.perks.indexOf('balance') < 0) {
                        continue;
                    }
                    post.values.push({
                        field: field.id,
                        value: amount,
                    });
                }
                if (!post.values.length) {
                    return;
                }
                const patchCashbox = await this.$api(
                    `/entity/cashbox/${id}`,
                    {
                        method: "PATCH",
                        body: post,
                    }
                );
                this.update(patchCashbox);
            } catch (e) {
                this.$error(e.message);
            }
        },

        async setCostAmount({ id, category, amount }) {
            try {
                const post = { id };
                let route = '';

                if (category === 'costs.template') {
                    post.remainsAmount = amount;
                    route = 'templates';
                } else {
                    post.amount = amount;
                    route = 'elements';
                }

                const result = await this.$api(
                    `/finance/costs/${route}`,
                    {
                        method: "PATCH",
                        body: post,
                    }
                );

                if (result.id) {
                    // @todo убрать когда на ws будет
                    setTimeout(() => {
                        this.updateCosts = Math.random();
                    }, 300);
                }
            } catch (e) {
                this.$error(e.message);
            }
        },

        doUpdateCosts() {
            this.updateCosts = Math.random();
        },

        startUpdateTimer() {
            const that = this;
            setTimeout(function() {
                that.updateCashboxes = Math.random();
                that.updateCosts = Math.random();
                that.updateTransactions = Math.random();
                that.startUpdateTimer();
            }, this.updateTimeout);
        },

        getTransactionMatrix(from, to) {
            const toSelf = (from.id === to.id) && (from.category === to.category);

            const vendorCashboxes = ['vendor', 'salary', 'storage'];
            const fromVendor = vendorCashboxes.includes(from.subcategory);
            const toVendor = vendorCashboxes.includes(to.subcategory);

            const fromInstallments = from.subcategory === 'installments';
            const toInstallments = to.subcategory === 'installments';

            const fromCosts = from.category === 'costs';
            const toCosts = to.category === 'costs';

            const linkTypes = ['siteTransaction', 'siteSplit', 'siteDelivery'];
            const fromLinks = linkTypes.includes(from.category);
            const toLinks = linkTypes.includes(to.category);

            let result = {
                isAllowed: false,
                from: null,
                to: null,
                addLink: null,
            };

            if (toSelf
                || (fromVendor && toVendor)
                || (fromVendor && toCosts)
                || (fromVendor && toLinks)
                || (fromVendor && toInstallments)

                || (fromCosts && toCosts)
                || (fromCosts && toVendor)

                || (fromLinks && toLinks)
                || (fromLinks && toVendor)

                || (fromInstallments && toInstallments)
                || (fromInstallments && toVendor)
            ) {
                return result;
            }

            result.isAllowed = true;

            if (toCosts || toLinks) {
                if (fromInstallments) {
                    result.from = 1;
                } else {
                    result.from = -1;
                }
                result.addLink = 'to';
                return result;
            }

            if (fromCosts || fromLinks) {
                if (toInstallments) {
                    result.to = -1;
                } else {
                    result.to = 1;
                }
                result.addLink = 'from';
                return result;
            }

            if (fromVendor || fromInstallments) {
                result.from = 1;
                result.to = 1;
                return result;
            }

            if (toVendor || toInstallments) {
                result.from = -1;
                result.to = -1;
                return result;
            }

            result.from = -1;
            result.to = 1;

            return result;
        },

        async editTransaction({ id, body }) {
            try {
                const patchTransaction = await this.$api(
                    `/finance/transaction/${id}`,
                    {
                        method: "PATCH",
                        body,
                    }
                );

                if (typeof patchTransaction.id != 'undefined') {
                    // @todo убрать когда на ws будет
                    setTimeout(() => {
                        this.updateTransactions = Math.random();
                    }, 300);
                }
            } catch (e) {
                this.$error(e.message);
            }
        },

        selectItem(item, category, subcategory, balance = 0) {
            if (this.selectedItem.id) {
                if (this.selectedItem.id !== item.id || this.selectedItem.category !== category) {
                    const from = this.selectedItem;

                    const to = {
                        id: item.id,
                        title: item.name || item.title,
                        category,
                        subcategory,
                        balance,
                    };

                    const isCostsWithZeroBalance =
                        (to.category === 'costs')
                        && (to.balance <= 0);
                    
                    if (isCostsWithZeroBalance) {
                        this.$error('Пополните расход перед списанием');
                        return;
                    }

                    let amount = 0;
                    const vendorTypes = ['vendor', 'salary', 'installments', 'storage'];
                    if (to.category === 'costs') {
                        amount = Number(to.balance);
                    } else if (vendorTypes.includes(to.subcategory)) {
                        amount = Number(to.balance);
                    } else if (from.subcategory === 'manager') {
                        amount = Number(from.balance);
                    }

                    this.openTransactionDialogCreate(from, to, item.currency, amount, '');
                }

                this.clearSelection();
            } else {
                this.selectedItem = {
                    id: item.id,
                    title: item.name || item.title,
                    category,
                    subcategory,
                    balance,
                };

                this.$refs.selectedItem.show(item, category);

                let type = '';
                if (category === 'costs') {
                    type = [category, subcategory].join('.');
                } else {
                    type = category;
                }

                this.modifyFilter({
                    type,
                    value: item.id,
                });
            }
        },

        newCostTemplate(template) {
            const newTemplate = JSON.parse(template);
            const list = this.costTemplates.filter((tm) => tm.id == newTemplate.id);
            if (list.length) {
                return;
            }
            this.costTemplates.push(newTemplate);
        },

        newCostElement(element) {
            const newElement = JSON.parse(element);
            const list = this.costElements.filter((el) => el.id == newElement.id);
            if (list.length) {
                return;
            }
            this.costElements.push(newElement);
        },

        clearSelection() {
            this.modifyFilter(this.transactionFilterModifier);

            this.selectedItem = {
                id: null,
                title: null,
                category: null,
                subcategory: null,
                balance: null,
            };

            this.$refs.selectedItem.hide();
        },

        modifyFilter({ type, value }) {
            if (
                this.transactionFilterModifier.type === type
                && this.transactionFilterModifier.value === value
            ) {
                this.transactionFilterModifier = {
                    type: undefined,
                    value: undefined,
                };
            } else {
                this.transactionFilterModifier = {
                    type,
                    value,
                };
            }
            this.updateTransactions = Math.random();
        },

        /**
         * @param {(
         * 'cashboxDialogMassProcess'
         * |'transactionDialogProcess'
         * |'transactionDialogEdit'
         * |'savedFiltersDialog'
         * |'dialogSetAmount'
         * )} ref
         */
        openDialog(ref, $event) {
            if (this.$refs[ref]) {
                this.$refs[ref].open($event);
            }
        },

        openTransactionDialogCreate(from, to, currency, initialAmount, initialComment) {
            const transaction = this.getTransactionMatrix(from, to);

            if (transaction.isAllowed) {
                this.$refs.transactionDialogCreate.open(from, to, currency, initialAmount, initialComment);
            }
        },

        openFilterDialogEdit(filter = {}, meta = {}, isEditing = false) {
            if (isEditing) {
                const newFilter = {};
                Object.keys(this.defaultFilter).forEach(key => {
                    if (
                        Object.prototype.hasOwnProperty.call(filter, key)
                            && JSON.stringify(filter[key]) !== JSON.stringify(this.defaultFilter[key])
                    ) {
                        newFilter[key] = filter[key];
                    } else {
                        newFilter[key] = this.defaultFilter[key];
                    }
                });
                this.$refs.filterDialogEdit.open(newFilter, meta, true);
            } else {
                this.$refs.filterDialogEdit.open(this.transactionFilter, meta, false);
            }
        },
    },
};
</script>

<style lang="scss">
.theme--light .selected-item {
    background-color: #90CAF9 !important; /** blue lighten-3 */
}

.theme--dark .selected-item {
    background-color: #0D47A1 !important; /** blue darken-4 */
}

.theme--light .dragged-item {
    background-color: #90CAF9 !important; /** blue lighten-3 */
}

.theme--dark .dragged-item {
    background-color: #0D47A1 !important; /** blue darken-4 */
}

.theme--light .drop-point {
    background-color: #90CAF9 !important; /** blue lighten-3 */
}

.theme--dark .drop-point {
    background-color: #0D47A1 !important; /** blue darken-4 */
}

.drop-point {
    a {
        pointer-events: none !important;
    }
}

.vue-cashbox-basket * {
    pointer-events: none;

    a {
        pointer-events: auto;
    }
}

.balance-changed {
    animation: balance-changed 2s;
}
@keyframes balance-changed {
    0% {
        background-color: inherit;
    }
    50% {
        background-color: #90CAF9; /** blue lighten-3 */
    }
    100% {
        background-color: inherit;
    }
}
</style>
